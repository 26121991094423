import request from '@/utils/request';
import request2 from "@/utils/request2";
import langRequest from '@/utils/langRequest';

export function priceItem(query,headers) {
    return request2({
        url: '/ppl_price_item',
        method: 'get',
        params: query,
        headers:headers
    });
}

/*
* 参数名 统一用id language
*/
export function bannerList(query) {
    return request({
        url: '/banner',
        method: 'get',
        params: query
    });
}

export function activeList(query) {
    return request({
        url: '/active',
        method: 'get',
        params: query
    });
}

export function activeDetail(query) {
    return request({
        url: '/active-detail',
        method: 'get',
        params: query
    });
}

export function discountList(query) {
    return request({
        url: '/discount',
        method: 'get',
        params: query
    });
}

export function discountDetail(query) {
    return request({
        url: '/discount-detail',
        method: 'get',
        params: query
    });
}

export function salesList(query) {
    return request({
        url: '/sales',
        method: 'get',
        params: query
    });
}

export function salesDetail(query) {
    return request({
        url: '/sales-detail',
        method: 'get',
        params: query
    });
}

export function famousForumList(query) {
    return request({
        url: '/famous-forum',
        method: 'get',
        params: query
    });
}

export function famousForumDetail(query) {
    return request({
        url: '/famous-detail',
        method: 'get',
        params: query
    });
}

export function menuList(query) {
    return request({
        url: '/menu',
        method: 'get',
        params: query
    });
}

export function companyNotice(query) {
    return request({
        url: '/company-notice',
        method: 'get',
        params: query
    });
}

export function companyNoticeDetail(query) {
    return request({
        url: '/company-notice-detail',
        method: 'get',
        params: query
    });
}

export function video(query) {
    return request({
        url: '/video',
        method: 'get',
        params: query
    });
}

